import request from "@/api/request";

export default {
  // 新增
  addOutlets: async data => request({url: '/console/outlets.Outlets/addOutlets', data}),
  // 编辑
  editOutlets: async data => request({url: '/console/outlets.Outlets/editOutlets', data}),
  // 列表
  getList: async data => request({url: '/console/outlets.Outlets/getList', data}),
  // 删除
  delOutlets: async data => request({url: '/console/outlets.Outlets/delOutlets', data}),
  // 批量启用
  batchEnable: async data => request({url: '/console/outlets.Outlets/batchEnable', data}),
  //批量禁用
  batchStop: async data => request({url: '/console/outlets.Outlets/batchStop', data}),
  // 批量删除
  batchDel: async data => request({url: '/console/outlets.Outlets/batchDel', data}),
  // 启用/禁用
  changeState: async data => request({url: '/console/outlets.Outlets/changeState', data}),
  // 设置
  setOutlets: async data => request({url: '/console/outlets.Outlets/setOutlets', data}),
  // 详情
  getDetail: async data => request({url: '/console/outlets.Outlets/getDetail', data}),
  // 门店商品-列表
  getGoodsList: async data => request({url: '/console/outlets.Outlets/getGoodsList', data}),
  // 门店商品-批量分配
  batchDistribution: async data => request({url: '/console/outlets.Outlets/batchDistribution', data}),
  // 门店商品-批量取消分配
  batchUnDistribution: async data => request({url: '/console/outlets.Outlets/batchUnDistribution', data}),
  // 门店商品-列表分配/取消
  listDistribution: async data => request({url: '/console/outlets.Outlets/listDistribution', data}),
  // 门店商品-一键分配至门店
  oneDistribution: async data => request({url: '/console/outlets.Outlets/oneDistribution', data}),
  // 门店商品-一键取消分配至门店
  oneUnDistribution: async data => request({url: '/console/outlets.Outlets/oneUnDistribution', data}),
  // 门店商品-一键取消分配至门店
  setRule: async data => request({url: '/console/outlets.Outlets/setRule', data}),
  // 门店商品-一键取消分配至门店
  getRule: async data => request({url: '/console/outlets.Outlets/getRule', data}),

  // 门店信息
  info: async data => request({url: '/console/outlets.Outlets/info', data}),
  // 门店
  storeList: async data => request({url: '/console/goods.Product/storeList', data}),
  // 分配店铺
  saveStore: async data => request({url: '/console/goods.Product/saveStore', data}),
  // 批量分配店铺
  batchStore: async data => request({url: '/console/goods.Product/batchStore', data}),

  unAssignStore: async data => request({url: '/console/goods.Product/unAssignStore', data}),
}
