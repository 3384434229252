import request from "@/api/request";

export default {
    // 分类-添加
    addCategory: async data => request({url: '/console/information.Category/addCategory', data}),
    // 分类-编辑
    editCategory: async data => request({url: '/console/information.Category/editCategory', data}),
    // 分类-列表
    ClassList: async data => request({url: '/console/information.Category/getList', data}),
    // 分类-删除
    delCategory: async data => request({url: '/console/information.Category/delCategory', data}),
    // 资讯-添加
    addInformation: async data => request({url: '/console/information.Information/addInformation', data}),
    // 资讯-编辑
    editInformation: async data => request({url: '/console/information.Information/editInformation', data}),
    // 资讯-列表
    getList: async data => request({url: '/console/information.Information/getList', data}),
    // 资讯-删除/批量删除
    delInformation: async data => request({url: '/console/information.Information/delInformation', data}),
    // 资讯-详情
    getDetail: async data => request({url: '/console/information.Information/getDetail', data}),
    // 资讯-添加-分类下拉列表
    getCategory: async data => request({url: '/console/information.Information/getCategory', data}),
    // 批量删除
    batchDel: async data => request({url: '/console/information.Information/batchDel', data}),

    updateState: async data => request({url: '/console/information.Information/updateState', data}),
    updateTop: async data => request({url: '/console/information.Information/updateTop', data}),
    updateSort: async data => request({url: '/console/information.Information/updateSort', data}),
}
