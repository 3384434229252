import request from "@/api/request";

export default {
    // 积分兑换明细
    getPoint: async data => request({ url: '/console/user.User/getPoint', data }),
    // 商品订单
    getGoods: async data => request({ url: '/console/user.User/getGoods', data }),
    //列表
    getList: async data => request({ url: '/console/user.User/getList', data }),
    // 启用禁用
    changeState: async data => request({ url: '/console/user.User/changeState', data }),
    // 售后维修
    getPostSale: async data => request({ url: '/console/user.User/getPostSale', data }),
    // 积分明细
    getPointDetail: async data => request({ url: '/console/user.User/getPointDetail', data }),
    //优惠券明细
    getCoupon: async data => request({ url: '/console/user.User/getCoupon', data }),
    // 验光预约
    getOptometry: async data => request({ url: '/console/user.User/getOptometry', data }),
    // 详情
    getDetail: async data => request({ url: '/console/user.User/getDetail', data }),

    getFamily: async data => request({ url: '/console/user.User/getFamily', data }),
}
