import request from "@/api/request";

export default {
    // 验光预约评价列表
    getList: async data => request({url: '/console/orders.Comment/getOptometryList', data}),

   // 删除评价
    delComment: async data => request({url: '/console/orders.Comment/delComment', data}),

    // 展示/隐藏评论
    changeState: async data => request({url: '/console/orders.Comment/changeState', data}),

    //回复
    replay: async data => request({url: '/console/orders.Comment/replay', data}),

    delRepairComment: async data => request({url: '/console/orders.Comment/delRepairComment', data}),
}
