import request from "@/api/request";

export default {
    // 列表
    getList: async data => request({url: '/console/market.Points/getList', data}),

    // 新增优惠卷
    addReddem: async data => request({url: '/console/market.Points/add', data}),

    // 编辑优惠卷
    edit: async data => request({url: '/console/market.Points/edit', data}),

    // 详情
    getDetail: async data => request({url: '/console/market.Points/getDetail', data}),

    // 数据列表
    getActList: async data => request({url: '/console/market.Points/getActList', data}),

    // 立即开始
    begin: async data => request({url: '/console/market.Points/begin', data}),

    // 立即结束
    over: async data => request({url: '/console/market.Points/over', data}),

    // 删除
    del: async data => request({url: '/console/market.Points/del', data}),
}
